.home-wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
}

/* Jumbotron / Banner */

/* Image Opacity */
.bg-opacity {
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url(../assets/bg-update-3.png) center, center;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-color: rgba(46, 64, 82, 0.4); */
  opacity: 0.4;
  width: 100%;
  max-height: 800px;
}

.banner-content {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  align-items: center;
}

.banner-content .head-portrait {
  display: flex;
  object-fit: cover;
  border-radius: 75px;
  max-height: 150px;
  max-width: 150px;
  -moz-box-shadow: 0px 3px 15px 4px #2e4052;
  -webkit-box-shadow: 0px 3px 15px 4px #2e4052;
  box-shadow: 0px 3px 15px 4px #2e4052;
}

.social-links {
  display: flex;
  justify-content: space-between;
  margin: auto;
}

.social-links a {
  color: white;
  font-size: 3em;
  padding: 15px;
}

.social-links a:hover {
  color: #3f3d56;
}

.home-skills {
  padding-bottom: 50px;
}

/* Home Cards */

/* .home-content-wrapper {
  display: flex;
  min-height: 100%;
} */

.home-introduction {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  max-width: 100%;
  height: 500px;
  background-color: #fafafa;
}

.home-content {
  display: flex;
  flex-direction: column;
  align-content: center;
  max-width: 70%;
  margin: auto;
  margin-bottom: 10px;
  margin-top: 10px;
}

@media only screen and (max-width: 600px) {
  .home-content {
    max-width: 90%;
  }
}
