.contact-wrapper {
  max-width: 600px;
  margin-top: 15px;
  margin: 10px;
}

.contact-color-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  min-height: 50vh;
  background-color: #c6cee3;
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 25px 0 25px 0;
}

.contact-page-color-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  min-height: 60vh;
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 25px 0 25px 0;
}
