.projects-wrapper {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center; */
  margin: auto;

  /* border: 1px solid black; */
  margin-top: 100px;
  max-width: 900px;
}

.project-image {
  max-width: 100%;
}
