.footer-wrapper {
  max-height: 150px;
  position: relative;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  padding-top: 25px;
  margin-top: 15px;
  /* background-color: #fafafa; */
  /* border: 2px solid black; */
}
